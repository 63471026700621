.mains {
  width: 100%;
  height: 100%;
  background-color: lightgoldenrodyellow;
}

.head {
  width: 100%;
  height: 35px;
  background-color: burlywood;
  display: flex;
}

.head2 {
  width: 33%;
  height: 100%;
  display: flex;
}

.head2 a {
  margin-left: 50px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  margin-top: 08px;
}

.head2 p {
  margin-left: 20px;
  font-weight: bold;
  color: black;
  padding-top: 07px;
}

.head3 {
  width: 100%;
  height: 100%;
  margin-left: 0%;
  font-weight: bold;
  font-size: 22px;
}


.navigation {
  position: sticky;
  top: 0;
  z-index: 999;
  background-image: url(component/Img/Untitled.png);
  background-size: cover;
  background-repeat: no-repeat;
}


.navbar-brand,
.navbar-nav .nav-link {
  color: white;
  font-weight: bold;
  font-size: 17px;
  padding-left: 10px;
  margin-left: 30px;

}

.dropdown-menu {
  background-color: #fff;
}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: red;
}

.d-block {
  height: 500px;
  width: 100%;
  z-index: 999;
}

.heading:hover {
  color: red;
  font-weight: bold;
}


.contain {
  width: 95%;
  height: auto;
  display: flex;
  margin-top: 100px;
  margin-left: 25px;
}

.cont2 {
  width: 50%;
  height: 100%;
}

.cont2 img {
  width: 100%;
  height: 100%;
}

.cont2 h1 {
  margin-top: 10px;
  font-size: 28px;
  font-family: 'Times New Roman', Times, serif;
}

.cont2 p {
  font-size: 17px;
}

.cont2 span {
  margin-left: 70px;
}

.cont2 button {
  width: 150px;
  height: 35px;
  margin-left: 50px;
  margin-top: 30px;
  font-weight: bold;
  border: none;
  background-color: blue;
  border-radius: 10px;
  color: white;
}


.buses {
  width: 100%;
  height: auto;
  margin-top: 0px;
  display: flex;
  background-color: white;

}



.bus2 {
  width: 33%;
  height: 360px;
  margin-left: 0px;

}

.bus2 img {
  width: 380px;
  height: 300px;
  margin-left: 20px;
  margin-top: 10px;
}

.bus2 button {
  width: 150px;
  height: 40px;
  margin-left: 120px;
  margin-top: 90px;
  background-color: springgreen;
  color: black;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  font-size: 20px;
}

/* CSS for Bus Booking Form */
.bookingform {
  width: 97%;
  height: 400px;
  display: flex;
  margin-left: 15px;
  border: 1px solid gray;
  margin-top: 50px;
  border-radius: 10px;
}




.travel {
  width: 100%;
  height: 100%;

  margin-top: 20px;
  display: flex;
}

.trav2 {
  width: 33%;
  height: 350px;
  margin-left: 10px;

}


.trav2 img {
  width: 100%;
  height: 100%;
}

.images {
  width: 100%;
  height: 400px;
  display: flex;
  margin-top: 30px;
}

.imag2 {
  width: 24%;
  margin-left: 10px;

}

.imag2 img {
  width: 100%;
  height: 100%;
}




.footer {
  width: 100%;
  height: 390px;
  background-color: black;
  margin-top: 60px;
  display: flex;
}

.foot {

  height: 100%;
  width: 23%;
  margin-left: 0px;
}

.foot h1 {
  font-size: 24px;
  color: #f8d603;
  margin-left: 40px;
  padding-top: 20px;
}

.foot a {
  margin-left: 40px;
  color: white;
  text-decoration: none;

}

.foot img {
  margin-left: 40px;
  padding-top: 10px;
}



.payment {
  border: 1px solid white;
  width: 100%;
  height: 200px;
  background-color: black;
  display: flex;
}


.payment2 {
  width: 50%;
  height: 100%;

}

.payment2 img {
  width: 140px;
  height: 120px;
  margin-left: 200px;
}

.payment2 h1 {
  font-size: 22px;
  margin-left: 170px;
  padding-top: 40px;
  color: white;
}


.payment2 a img {
  height: 60px;
  width: 40px;
  margin-left: 40px;
}


/* Form container */
.form-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


}

.form-container button {

  border: none;
  margin-left: 10px;
  font-weight: bold;
  border-radius: 10px;
  height: 35px;
}

.form-container h2 {
  font-size: 20px;
}



/* Form labels */
label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Form inputs */
input[type="text"],
input[type="datetime-local"],
input[type="tel"],
input[type="email"] {
  width: calc(100% - 10px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

/* Submit button */
button[type="submit"] {
  width: 15%;

  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.form-container button {
  width: 100px;
  height: 35px;
  margin-left: 10px;
  margin-top: 30px;
  font-weight: bold;
  border: none;
  background-color: black;
  border-radius: 10px;
  color: white;
}

.plans {
  width: 100%;
  height: auto;

}

.plans h1 {
  text-align: center;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

.plans p {
  font-size: 20px;
  margin-left: 230px;
  color: black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.plans p:hover {
  color: gray;
  font-size: 22px;
}

.plans h2 {
  margin-left: 200px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  margin-top: 100px;
}

.plans h2 i {
  font-size: 20px;
}

.plans h4 {
  margin-left: 200px;
  font-size: 20px;
}

.whatspp {
  margin-left: 0px;
  bottom: 0;
  margin-top: 50px;
  position: fixed;
  bottom: 0;
}


.hire {
  width: 100%;
  height: 200px;
}

.hire h1 {
  text-align: center;
  margin-top: 50px;
}

.hire p {
  text-align: center;
  margin-top: 20px;
}

.cars {
  width: 88%;
  height: 400px;
  margin-left: 70px;
  display: flex;
}

.cars2 {
  width: 24%;
  height: 100%;
  border: 1px solid #0056b3;
  margin-left: 10px;
  background-color: #0056b3;
}

.cars2 img {
  width: 100%;
}

.cars2 h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 25px;
  color: white;
}


.cars2 button {
  width: 150px;
  height: 45px;
  margin-left: 60px;
  margin-top: 30px;
  background-color: #f8d603;
  color: black;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  font-size: 22px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

tr.border-bottom td {
  border-bottom: 1px solid #dddddd;
}

tr:not(:last-child) td {
  border-bottom: none;
}

.option {
  font-weight: bold;
}

.vehicle {
  font-style: italic;
}

.package {
  color: #007bff;
}



.slides {
  width: 96%;
  height: 350px;
  margin-top: 70px;
  margin-left: 15px;
}

.slick-slider {
  position: relative;
}


.slick-slide {
  margin: 0 10px;
}


.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}


.slick-dots li {
  margin: 0 5px;
}


.slick-dots li button {
  font-size: 10px;
  color: #333;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  transition: all 0.3s ease;
}


.slick-dots li.slick-active button {
  color: #fff;
}


.vans {
  width: 100%;
  height: 280px;
  text-align: center;
  border: 1px solid gray;
  border-radius: 05px;
  text-align: center;
  background-color: lightgray;
}

.vans img {
  width: 300px;
  height: 200px;
  margin-left: 50px;
  margin-top: 10px;
}

.vans h1 {
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
}


.icon-bar {
  position: fixed;
  top: 85%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
  text-decoration: none;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {

  color: white;
}

.icon-bar span {
  color: red;
  font-weight: bold;
  margin-left: 10px;
  font-size: 12px;
}


.icon-bar2 {
  position: fixed;
  top: 85%;
  margin-left: 90%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
}

.icon-bar2 a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
  text-decoration: none;
}

.icon-bar2 a:hover {
  background-color: #000;
}

.facebook {

  color: white;
}

.icon-bar2 span {
  color: red;
  font-weight: bold;
  margin-left: 10px;
  font-size: 12px;
}



.watermark {
  position: fixed;
  top: 10%;
  left: 50%;

  transform: translate(-50%, -50%);
  font-size: 2em;
  color: rgba(0, 0, 0, 0.1);
  pointer-events: none;

}

.tampo {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 60px;
}

.tampo2 {
  width: 48%;
  height: 100%;
  margin-left: 15px;
}

.tampo2 img {
  width: 100%;
  height: 100%;
}



@media (max-width: 600px) {



  .mains {
    width: 100%;
    height: 100%;

  }

  .head {
    width: 100%;
    height: auto;
    background-color: burlywood;
    display: flex;
    flex-direction: column;
  }

  .head2 {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .head2 a {
    margin-left: 10px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    margin-top: 08px;
    font-size: 15px;
  }

  .head2 p {
    font-size: 13px;
    margin-left: 20px;
  }

  .head3 {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    font-weight: bold;
    font-size: 22px;

  }

  .navigation {
    position: sticky;
    top: 0;
    z-index: 999;
    background-image: url(component/Img/Untitled.png);
    background-size: cover;
    background-repeat: no-repeat;

  }

  .d-block {
    height: 200px;
    width: 100%;
  }

  .carosal img {
    width: 100%;
    height: 200px;
  }

  .contain {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
    margin-top: 10px;
    margin-left: 0px;
  }

  .cont2 {
    width: 100%;
    height: 100%;
  }

  .cont2 img {
    width: 100%;
    height: 100%;
    margin-top: 20px;
  }



  .cont2 h1 {
    margin-top: 20px;
    font-family: 'Times New Roman', Times, serif;
    margin-left: 20px;
  }

  .cont2 p {
    font-size: 20px;
    margin-left: 15px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }


  .cont2 button {
    width: 150px;
    height: 35px;
    margin-left: 50px;
    margin-top: 30px;
    font-weight: bold;
    border: none;
    background-color: #f8d603;
    border-radius: 10px;
    display: none;
  }


  .buses {
    width: 100%;
    height: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  .bus2 {
    width: 100%;
    height: 400px;
    margin-left: 0px;
  }

  .bus2 img {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    margin-top: 10px;



  }


  .bus2 button {
    width: 150px;
    height: 40px;
    margin-left: 120px;
    margin-top: 100px;
    background-color: springgreen;
    color: black;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    font-size: 20px;
  }


  /* CSS for Bus Booking Form */
  .bookingform {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    border: none;
    margin-top: 50px;

  }




  .travel {
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-top: 20px;
    display: flex;
  }

  .trav2 {
    width: 100%;
    height: 350px;
    margin-left: 0px;
    margin-top: 10px;
  }

  .trav2 img {
    width: 100%;
    height: 100%;
  }


  .images {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
    margin-top: 10px;
  }

  .imag2 {
    width: 100%;
    margin-left: 0px;
    height: 400px;
    margin-top: 10px;
  }

  .imag2 img {
    width: 100%;
    height: 100%;
  }







  .footer {
    width: 100%;
    height: 100%;
    background-color: black;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  }

  .foot {

    height: 100%;
    width: 100%;
    margin-left: 0px;
  }

  .foot h1 {
    font-size: 24px;
    color: #f8d603;
    margin-left: 60px;
    padding-top: 20px;
  }

  .foot a {
    margin-left: 60px;
    color: white;
    text-decoration: none;

  }

  .foot img {
    margin-left: 60px;
    padding-top: 10px;
  }



  .payment {
    border: 1px solid white;
    width: 100%;
    height: auto;
    background-color: black;
    display: flex;
    flex-direction: column;
    margin-top: 02px;
  }


  .payment2 {
    width: 100%;
    height: 100%;

  }

  .payment2 img {
    width: 140px;
    height: 120px;
    margin-left: 60px;
  }

  .payment2 h1 {
    font-size: 22px;
    margin-left: 60px;
    padding-top: 40px;
    color: white;
  }



  .payment2 a img {
    height: 60px;
    width: 40px;
    margin-left: 40px;
  }

  button[type="submit"] {
    width: 60%;

    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .plans {
    width: 100%;
    height: auto;
    background-color: white;
  }

  .plans h1 {
    text-align: center;
  }

  .plans p {
    font-size: 20px;
    margin-left: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .plans p:hover {
    color: red;
    font-size: 22px;
  }

  .plans h2 {
    margin-left: 20px;
  }

  .hire {
    width: 100%;
    height: 200px;
  }

  .hire h1 {
    text-align: center;
    margin-top: 50px;
  }

  .hire p {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
  }

  .cars {
    width: 100%;
    height: auto;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
  }

  .cars2 {
    width: 100%;
    height: 100%;
    border: 1px solid #0056b3;
    margin-left: 0px;
    background-color: #0056b3;
    margin-top: 20px;
  }

  .cars2 img {
    width: 100%;
  }


  .cars2 button {
    width: 150px;
    height: 45px;
    margin-left: 90px;
    margin-top: 30px;
    background-color: #f8d603;
    color: black;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    font-size: 22px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
    font-size: 10px;
  }

  .td {
    font-size: 12px;
  }

  th {
    background-color: #f2f2f2;
  }

  tr.border-bottom td {
    border-bottom: 1px solid #dddddd;
  }

  tr:not(:last-child) td {
    border-bottom: none;
  }

  .option {
    font-weight: bold;
  }

  .vehicle {
    font-style: italic;
  }

  .package {
    color: #007bff;
  }

  .slides {
    width: 90%;
    height: 350px;
    margin-top: 70px;
    margin-left: 10px;
  }

  .slick-slider {
    position: relative;
  }


  .slick-slide {
    margin: 0 10px;
  }


  .slick-dots {
    position: absolute;
    bottom: 10px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }


  .slick-dots li {
    margin: 0 5px;
  }


  .slick-dots li button {
    font-size: 10px;
    color: #333;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    transition: all 0.3s ease;
  }


  .slick-dots li.slick-active button {
    color: #fff;
  }


  .vans {
    width: 100%;
    height: 280px;
    text-align: center;
    border: none;
    border-radius: 05px;

  }

  .vans img {
    width: 280px;
    height: 200px;
  }

  .vans h1 {
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
  }

  .icon-bar {
    position: fixed;
    top: 90%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
    margin-left: -20px;
  }

  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    text-decoration: none;
  }

  .icon-bar a:hover {
    background-color: #000;
  }

  .facebook {

    color: white;
  }

  .icon-bar span {
    color: red;
    font-weight: bold;
    margin-left: 10px;
    font-size: 12px;
  }


  .icon-bar2 {
    position: fixed;
    top: 90%;
    margin-left: 73%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
  }

  .icon-bar2 a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    text-decoration: none;
  }

  .icon-bar2 a:hover {
    background-color: #000;
  }

  .facebook {

    color: white;
  }

  .icon-bar2 span {
    color: red;
    font-weight: bold;
    margin-left: 10px;
    font-size: 12px;
  }


  .tampo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .tampo2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .tampo2 img {
    width: 100%;
    height: 100%;
  }




}









@media (min-width: 601px) and (max-width: 1250px) {
  .mains {
    width: 1250px;
    height: 100%;
  }

  .head {
    width: 100%;
    height: 35px;
    background-color: burlywood;
    display: flex;
  }

  .head2 {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .head2 a {
    margin-left: 50px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    margin-top: 08px;
  }

  .head2 p {
    margin-left: 20px;
    font-weight: bold;
    color: black;
    padding-top: 07px;
  }

  .head3 {
    width: 35%;

    height: 100%;
    margin-left: 60%;
    font-weight: bold;
    font-size: 22px;
  }


  .navigation {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: white;
  }




  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-weight: bold;
    font-size: 18px;
    padding-left: 10px;
    margin-left: 30px;

  }

  .dropdown-menu {
    background-color: #fff;
  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: red;
  }



  .d-block {
    height: 500px;
    width: 100%;
    z-index: 999;
  }

  .heading:hover {
    color: red;
    font-weight: bold;
  }


  .contain {
    width: 95%;
    height: auto;
    display: flex;
    margin-top: 100px;
    margin-left: 25px;
  }

  .cont2 {
    width: 50%;
    height: 100%;

  }

  .cont2 img {
    width: 100%;
    height: 100%;
  }

  .cont2 h1 {
    margin-top: 10px;
    font-size: 28px;
    font-family: 'Times New Roman', Times, serif;
  }

  .cont2 p {
    font-size: 17px;

  }

  .cont2 span {
    margin-left: 70px;
  }

  .cont2 button {
    width: 150px;
    height: 35px;
    margin-left: 50px;
    margin-top: 30px;
    font-weight: bold;
    border: none;
    background-color: #f8d603;
    border-radius: 10px;
  }


  .buses {
    width: 100%;
    height: auto;
    margin-top: 40px;
    display: flex;

  }

  .bus2 {
    width: 33%;
    height: 100%;
    margin-left: 0px;
  }

  .bus2 img {
    width: 100%;
    height: 100%;
  }

  .bus2 button {
    width: 150px;
    height: 45px;
    margin-left: 120px;
    margin-top: 50px;
    background-color: #f8d603;
    color: black;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    font-size: 25px;
  }

  /* CSS for Bus Booking Form */
  .bookingform {
    width: 97%;
    height: 400px;
    display: flex;
    margin-left: 15px;
    border: 1px solid gray;
    margin-top: 50px;
    border-radius: 10px;
  }




  .travel {
    width: 100%;
    height: 100%;

    margin-top: 20px;
    display: flex;
  }

  .trav2 {
    width: 33%;
    height: 350px;
    margin-left: 10px;

  }


  .trav2 img {
    width: 100%;
    height: 100%;
  }

  .images {
    width: 100%;
    height: 400px;

    display: flex;
    margin-top: 10px;
  }

  .imag2 {
    width: 24%;
    margin-left: 10px;

  }

  .imag2 img {
    width: 100%;
    height: 100%;
  }




  .footer {
    width: 100%;
    height: 390px;
    background-color: black;
    margin-top: 60px;
    display: flex;
  }

  .foot {

    height: 100%;
    width: 23%;
    margin-left: 0px;
  }

  .foot h1 {
    font-size: 24px;
    color: #f8d603;
    margin-left: 40px;
    padding-top: 20px;
  }

  .foot a {
    margin-left: 40px;
    color: white;
    text-decoration: none;

  }

  .foot img {
    margin-left: 40px;
    padding-top: 10px;
  }



  .payment {
    border: 1px solid white;
    width: 100%;
    height: 200px;
    background-color: black;
    display: flex;
  }


  .payment2 {
    width: 50%;
    height: 100%;

  }

  .payment2 img {
    width: 140px;
    height: 120px;
    margin-left: 200px;
  }

  .payment2 h1 {
    font-size: 22px;
    margin-left: 170px;
    padding-top: 40px;
    color: white;
  }


  .payment2 a img {
    height: 60px;
    width: 40px;
    margin-left: 40px;
  }


  /* Form container */
  .form-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


  }

  .form-container button {

    border: none;
    margin-left: 10px;
    font-weight: bold;
    border-radius: 10px;
    height: 35px;
  }

  .form-container h2 {
    font-size: 20px;
  }



  /* Form labels */
  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Form inputs */
  input[type="text"],
  input[type="datetime-local"],
  input[type="tel"],
  input[type="email"] {
    width: calc(100% - 10px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }

  /* Submit button */
  button[type="submit"] {
    width: 15%;

    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button[type="submit"]:hover {
    background-color: #0056b3;
  }

  .form-container button {
    width: 100px;
    height: 35px;
    margin-left: 10px;
    margin-top: 30px;
    font-weight: bold;
    border: none;
    background-color: black;
    border-radius: 10px;
    color: white;
  }

  .plans {
    width: 100%;
    height: auto;
    background-color: lightgoldenrodyellow;
  }

  .plans h1 {
    text-align: center;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
  }

  .plans p {
    font-size: 20px;
    margin-left: 230px;
    color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .plans p:hover {
    color: gray;
    font-size: 22px;
  }

  .plans h2 {
    margin-left: 200px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
  }

  .plans h2 i {
    font-size: 20px;
  }

  .plans h4 {
    margin-left: 200px;
    font-size: 20px;
  }

  .whatspp {
    margin-left: 0px;
    bottom: 0;
    margin-top: 50px;
    position: fixed;
    bottom: 0;
  }


  .hire {
    width: 100%;
    height: 200px;
  }

  .hire h1 {
    text-align: center;
    margin-top: 50px;
  }

  .hire p {
    text-align: center;
    margin-top: 20px;
  }

  .cars {
    width: 88%;
    height: 400px;
    margin-left: 70px;
    display: flex;
  }

  .cars2 {
    width: 24%;
    height: 100%;
    border: 1px solid #0056b3;
    margin-left: 10px;
    background-color: #0056b3;
  }

  .cars2 img {
    width: 100%;
  }

  .cars2 h1 {
    text-align: center;
    font-size: 30px;
    margin-top: 25px;
    color: white;
  }


  .cars2 button {
    width: 150px;
    height: 45px;
    margin-left: 60px;
    margin-top: 30px;
    background-color: #f8d603;
    color: black;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    font-size: 22px;
  }

  .slides {
    width: 96%;
    height: 350px;
    margin-top: 70px;
    margin-left: 15px;
  }

  .slick-slider {
    position: relative;
  }


  .slick-slide {
    margin: 0 10px;
  }


  .slick-dots {
    position: absolute;
    bottom: 10px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }


  .slick-dots li {
    margin: 0 5px;
  }


  .slick-dots li button {
    font-size: 10px;
    color: #333;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    transition: all 0.3s ease;
  }


  .slick-dots li.slick-active button {
    color: #fff;
  }


  .vans {
    width: 100%;
    height: 280px;
    text-align: center;
    border: 1px solid gray;
    border-radius: 05px;
  }

  .vans img {
    width: 300px;
    height: 200px;
  }

  .vans h1 {
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
  }

  .icon-bar {
    position: fixed;
    top: 85%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
  }

  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    text-decoration: none;
  }

  .icon-bar a:hover {
    background-color: #000;
  }

  .facebook {

    color: white;
  }

  .icon-bar span {
    color: red;
    font-weight: bold;
    margin-left: 10px;
    font-size: 12px;
  }


  .icon-bar2 {
    position: fixed;
    top: 85%;
    margin-left: 90%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
  }

  .icon-bar2 a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    text-decoration: none;
  }

  .icon-bar2 a:hover {
    background-color: #000;
  }

  .facebook {

    color: white;
  }

  .icon-bar2 span {
    color: red;
    font-weight: bold;
    margin-left: 10px;
    font-size: 12px;
  }


  .tampo {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 60px;
  }

  .tampo2 {
    width: 48%;
    height: 100%;
    margin-left: 15px;
  }

  .tampo2 img {
    width: 100%;
    height: 100%;
  }



}