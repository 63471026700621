.buses {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.bus2 {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.bus2 p {
  display: none;
}

.bus2 img {
  width: 100%;
  height: 400px;
  transition: transform 0.3s ease;
}

.bus2:hover img {
  transform: scale(1.2);

}

.bus2 button {
  position: absolute;
  bottom: 10px;
  left: 10%;
  margin-top: 0%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bus2:hover button {
  background-color: #0056b3;

}



.modal {
  height: 600px;
 
  margin-top: 50px;
  width: 100%;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
}


.modal-content {
  text-align: center;
  background-color: grey;
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;
}


.modal-content img {
  width: 90%;
  height: auto;
  margin-bottom: 20px;
  margin-left: 20px;
}


.modal-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.modal-content button:hover {
  background-color: #0056b3;
}

.bus2 {
  position: relative;
  overflow: hidden;
}

.bus2 p {

  top: 70%;
  left: 100px;
  padding-top: 100px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.modal-content p {

  top: 57%;
  width: 200px;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}




@media (max-width: 768px) {
  .modal {
    height: 600px;
    margin-top: 50px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0px;
    border-radius: 5px;
    z-index: 1000;
  }

  .modal-content {
    text-align: center;
    background-color: grey;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .modal-content img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-left: 0px;
   
  }

  .modal-content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .modal-content button:hover {
    background-color: #0056b3;
  }

  .bus2 {
    position: relative;
    overflow: hidden;
  }

  .bus2 p {
    top: 70%;
    left: 100px;
    padding-top: 100px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
  }

  .modal-content p {
    top: 57%;
    width: 200px;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
  }
}
